.home-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 100svh;
    touch-action:none;
    .home-page{
        position: relative;
        padding-top: 60px;
        width: 900px;
        height: 100%;
        @media (max-width: 975px) {
            width: 100%;
            padding-top: 0px;
        }
        .blob-canvas{
            @media(min-height: 750px){
                height: 350px;
            }
        }
        .header{
            font-family: "Major Mono Display Regular";
            font-size: 72px;
            @media (max-width: 975px) {
                font-size: 56px;
                padding: 15px;
                margin-top: 20px;
            }
        }
        .nav-container{
            position: absolute;
            bottom: 40px; 
            right: 0px;
            @media (max-width: 975px) {
                bottom: 20px;
            }
        }
    }
}

.about-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    touch-action:none;
    height: 100%;
    .about-me{
        position: relative;
        padding-top: 60px;
        width: 900px;
        height: 100%;
        @media (max-width: 975px) {
            width: 100%;
            padding-top: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .skills-tool-box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }
}

.resume-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
        @media (max-width: 768px) {
            padding-top: 20px;
        }
    .resume{ 
        display: flex;
        flex-direction: row;
        @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
        }
        .sidebar{
            position: sticky;
            top: 60px;
            height: 100%;
            z-index: 1;
            background-color: white;
            @media (max-width: 768px) {
                top: -170px;
                padding-top: 20px;
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;
            }
            .tag-line{
                padding-bottom: 60px;
                padding-left: 5px;
                @media (max-width: 768px) {    
                    padding-left: 0px;
                    padding-bottom: 30px;
                }
                .full-name{
                    padding-bottom: 0px !important;
                    margin-bottom: 5px;
                }
                .title{
                    margin-right: auto;
                    margin-top: 0;
                    color: #ff7429;
                    font-size: large;
                    margin-bottom: 0;
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
            }
            .timeline-container{
                display: flex;
                width: 280px;
                height: calc(100vh - 270px);
                @media (max-width: 768px) {
                    height: 100%;
                    align-items: center;
                    width: 100%;
                }
                .steps{
                    width: 100%;
                    @media (max-width: 768px) {
                        width: 100%;
                        height: 100px;
                    }
                    > * {
                        flex: auto;
                    }
                }
            }
        }
        .main-body{
            width: 700px;
            overflow-y: auto;
            position: relative;
            padding-left: 0px;
            padding-right: 0px;
            @media (max-width: 975px) {
                width: 100%;
            }
            .resume-header{
                padding-bottom: 0px !important;
                margin-bottom: 5px;
                @media (max-width: 768px) {
                    padding: 15px;
                    margin: 0;
                }
            }
            .about-me-text{
                @media (max-width: 768px) {
                padding: 15px;
                }
            }
            .resume-section{
                width: 100%;
                @media (max-width: 768px) {
                    padding: 15px;
                    box-sizing: border-box;
                }
                .education-header{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    @media (max-width: 975px) {
                        flex-direction: column;
                    }
                    .company{
                        margin-right: auto;
                        margin-top: 0;
                        color: #ff7429;
                        font-size: large;
                        @media (max-width: 768px) {
                            margin-bottom: 0;
                            font-size: 14px;
                        }
                    }
                    .dates{
                        margin-left: auto;
                        margin-top: 0;
                        color: #ff7429;
                        font-size: large;
                        @media (max-width: 975px) {
                            margin-left: 0;
                            font-size: 14px;
                        }
                    }
                }
                .experience-header{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    @media (max-width: 975px) {
                        flex-direction: column;
                    }
                    .company{
                        margin-right: auto;
                        margin-top: 0;
                        color: #ff7429;
                        font-size: large;
                        @media (max-width: 768px) {
                            margin-bottom: 0;
                            font-size: 14px;
                        }
                    }
                    .dates{
                        margin-left: auto;
                        margin-top: 0;
                        color: #ff7429;
                        font-size: large;
                        @media (max-width: 975px) {
                            margin-left: 0;
                            font-size: 14px;
                        }
                    }
                }
                .position{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    .title{
                        margin-right: auto;
                        margin-top: 0;
                        color: #ff7429;
                        font-size: medium;
                        @media (max-width: 768px) {
                            font-size: small;
                        }
                    }
                }
                .resume-media{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    .image{
                        width: 100%;
                    }
                }
                ul{
                    list-style-type: disc;
                }
                .first-sentence{ 
                    font-weight: bold;
                }
                .title-description {
                    padding-top: 10px;                        
                }
                .education-description {
                    padding-top: 0px;
                    padding-bottom: 200px;
                    @media (max-width: 768px) {
                        padding-bottom: 100px;
                    }
                    .link-button {
                        padding: 0px;
                        margin: 0px;
                        height: 12px;
                    }              
                }
            }
        }
    }
}

.ant-tooltip{
    display: none;
}