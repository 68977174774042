.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Anton";
  src: url("https://db.onlinewebfonts.com/t/0d4bd6300a54d19c5fc62fe7e07c48cb.eot");
  src: url("https://db.onlinewebfonts.com/t/0d4bd6300a54d19c5fc62fe7e07c48cb.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/0d4bd6300a54d19c5fc62fe7e07c48cb.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/0d4bd6300a54d19c5fc62fe7e07c48cb.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/0d4bd6300a54d19c5fc62fe7e07c48cb.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/0d4bd6300a54d19c5fc62fe7e07c48cb.svg#Anton")format("svg");
}

@font-face {
  font-family: "Major Mono Display Regular";
  src: url("https://db.onlinewebfonts.com/t/f04ca116c6a8428796213da3aee2f29e.eot");
  src: url("https://db.onlinewebfonts.com/t/f04ca116c6a8428796213da3aee2f29e.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/f04ca116c6a8428796213da3aee2f29e.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/f04ca116c6a8428796213da3aee2f29e.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/f04ca116c6a8428796213da3aee2f29e.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/f04ca116c6a8428796213da3aee2f29e.svg#Major Mono Display Regular")format("svg");
}
