* {
	box-sizing: border-box;
}

.skeuomorph-button-container {
    margin-bottom: 20px;
    .skeuomorph-button {
        background-color: white;
        color: #000;
        font-family: "Major Mono Display Regular";
        border-radius: 18px;
        height: 80px;
        width: 300px;
        left: 0;
        right: 0;
        border: 0;
        margin: auto;
        position: relative;
        text-shadow: 2px 2px 3px rgba(0,0,0,.2);
        font-size: 18px;
        box-shadow: 
            -6px -6px 26px rgba(255,255,255,.83),
            5px 10px 15px rgba(0,0,0,.05),
            15px 20px 35px rgba(0,0,0,.1),
            inset -2px -2px 2px rgba(0,0,0,.1),
            inset -1px -1px 3px rgba(255,255,255,.6),
            inset 2px 2px 8px rgba(255,255,255,.2),
            inset 1px 1px 8px rgba(255,255,255,.2),
            -6px -6px 26px rgba(255,255,255,.8);
        transition: 300ms ease-out;
        cursor: pointer;
        &:hover {
            box-shadow: 
                4px 4px 6px rgba(0,0,0,.2), 
                5px 10px 35px rgba(0,0,0,.1),
                8px 10px 25px rgba(0,0,0,.1),
                inset -1px -1px 9px rgba(0,0,0,.2),
                inset -1px -1px 3px rgba(255,255,255,.6),
                inset 1px 1px 8px rgba(255,255,255,1),
                inset 4px 4px 24px rgba(0,0,0,.15),
                -5px -5px 15px rgba(255,255,255,.9);
            transform: scale(.98);
            

        }
        
        &:active, &:focus {
                box-shadow: inset 2px 2px 16px rgba(255,255,255,.9), inset 8px 8px 9px rgba(0,0,0,.2), 5px 10px 5px rgba(0,0,0,.03), -5px -5px 25px rgba(255,255,255,.1), -2px -2px 9px rgba(100,100,100,.1), inset -2px -2px 9px rgba(255,255,255,1);
                outline: none;
                background-color: #e5e5ef;
            text-shadow: -2px -2px 3px rgba(0,0,0,.2), 2px 2px 3px rgba(255,255,255,1);
            transform: translateY(3px);
            }

        @media (max-width: 975px) {
            height: 60px;
            width: 200px;
        }
    }
    @media (max-width: 975px) {
        margin-right: 15px;
        margin-bottom: 15px;
    }
}